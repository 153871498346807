body {
    height: max-content;
    width: 100vw;
    background-color: #000;
}

.app__body {
    height: max-content;
    width: 100vw;
    background-color: #000;
    margin: 0;
}

@media only screen and (max-width: 600px) {
    .app__body {
        padding-top: 0px;
    }
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

@media only screen and (max-width: 600px) {
    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #101010;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #303030;
}